import React, { useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "./App.scss";

import { ReactComponent as TorumNavbarLogo } from "./assets/torum-labs-logo-navbar.svg";
import { ReactComponent as TorumNavbarLogoSmall } from "./assets/torum-labs-logo-navbar-small.svg";
import { ReactComponent as ScrollToTopArrow } from "./assets/scroll-to-top-button.svg";
import Torum3dLogo from "./assets/torum-logo-3d.png";
import TorumLabs3dLogo from "./assets/torum-labs-logo-3d.png";

import HeroVideo from "./assets/video/hero-torum-labs.mp4";

import { isWebpSupported } from "react-image-webp/dist/utils";
// import Typer from "./Components/Typer";

import { projects } from "./projects.js";

var PreloadEmbedVideo = function (props) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
       <video
         loop
         muted
         autoplay
         playsinline
         preload
         poster="${props.poster}"
         src="${props.src}"
         class="${props.className}"
         id="${props.id}"
       />,
     `,
      }}
    ></div>
  );
};

function App() {
  const hero = useRef(null);
  const background = useRef(null);
  const about = useRef(null);
  const portfolio = useRef(null);

  const heroScroll = () => {
    hero.current.scrollIntoView({ behavior: "smooth" });
  };

  const backgroundScroll = () => {
    background.current.scrollIntoView({ behavior: "smooth" });
  };

  const aboutScroll = () => {
    about.current.scrollIntoView({ behavior: "smooth" });
  };

  const portfolioScroll = () => {
    portfolio.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="app-main-container">
      <div className="navbar-main-container">
        {window.innerWidth >= 576 ? (
          <div className="navbar-torum-logo-container">
            <TorumNavbarLogo />
          </div>
        ) : (
          <TorumNavbarLogoSmall
            className="navbar-logo"
            onClick={() => heroScroll()}
          />
        )}

        <div className="navbar-item-list-container">
          <div className="navbar-item" onClick={() => heroScroll()}>
            HOME
          </div>
          <div className="navbar-item" onClick={() => backgroundScroll()}>
            BACKGROUND
          </div>
          <div className="navbar-item" onClick={() => aboutScroll()}>
            ABOUT
          </div>
          <div className="navbar-item" onClick={() => portfolioScroll()}>
            PORTFOLIO
          </div>
        </div>

        <div className="navbar-button-container">
          <a
            href={"https://bsu5ua93efj.typeform.com/to/VkfgZ1QK"}
            target="_blank"
            rel="noopener noreferrer"
            className="redirect-link"
          >
            <div className="navbar-button apply">APPLY</div>
          </a>
          <a
            href={"https://intro.torum.com/"}
            target="_blank"
            rel="noopener noreferrer"
            className="redirect-link"
          >
            <div className="navbar-button intro">INTRO</div>
          </a>
        </div>
      </div>

      <PreloadEmbedVideo
        ref={hero}
        id="hero-video"
        preload
        src={HeroVideo}
        poster={
          isWebpSupported()
            ? "https://landing-page-torum.s3.us-east-2.amazonaws.com/hero-torum-labs-fallback.webp"
            : "https://landing-page-torum.s3.us-east-2.amazonaws.com/hero-torum-labs-fallback.png"
        }
      />

      <div className="hero-main-container" ref={hero}>
        <div className="hero-main-content-container">
          {/* <Typer dataText={["For Founders, By Founders."]} /> */}
          <div className="hero-title">For Founders, By Founders.</div>
          <div className="hero-description">
            We empower passionate and visionary entrepreneurs and invest in
            sustainable crypto startups that strive to transform the entire
            blockchain landscape.
          </div>
        </div>
      </div>

      <ScrollAnimation animateIn="fadeIn" duration={2}>
        <div className="background-main-container background" ref={background}>
          <img src={Torum3dLogo} className="background-logo" />

          <div className="background-text-container">
            <div className="section-title">
              Our <span className="gradient">Background</span>
            </div>
            <div className="background-description">
              Founded in 2018, Torum is a grassroot startup that focuses on
              aggregating worldwide crypto communities, news and information to
              provide value for blockchain users and projects on a global scale.
            </div>
            <div className="background-description">
              Started as a five-man team, we are now an expanding organization
              with more than 30 talents across the globe. Torum is currently the
              leading SocialFi ecosystem that aims to become the mainstream
              crypto-focused social media platform in the crypto space.
            </div>
            <div className="background-description">
              Here we are, proud to present Torum Labs — the corporate venture
              capital arm of Torum.
            </div>
          </div>
        </div>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" duration={2}>
        <div className="background-main-container about" ref={about}>
          <div className="background-text-container">
            <div className="section-title">
              <span className="gradient">About</span> Torum Labs
            </div>
            <div className="background-description">
              Torum Labs strives to recognize, fund and empower passionate
              crypto entrepreneurs that are dedicated to build blockchain
              products and services, investing in sustainable crypto startups
              that are capable of expanding the entire blockchain landscape.
            </div>
            <div className="background-description">
              We are devoted to deliver significant support in terms of
              marketing and providing other relevant connections for highly
              competent teams of developers and engineers that can help
              transform and accelerate the growth of the crypto space in the
              long run.
            </div>
          </div>
          <img src={TorumLabs3dLogo} className="background-logo" />
        </div>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" duration={2}>
        <div className="portfolio-main-container" ref={portfolio}>
          <div className="section-title">
            Our <span className="gradient">Portfolio</span>
          </div>
          <div className="portfolio-item-container">
            {projects.map((el) => {
              return (
                <a
                  href={el.websiteUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="portfolio-item-wrapper"
                >
                  <img src={el.imageUrl} className="portfolio-item-image" />
                  <div className="portfolio-item-name">{el.name}</div>
                  <div className="portfolio-item-description">
                    {el.description}
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" duration={2}>
        <div className="apply-main-container">
          <div className="section-title">
            Get <span className="gradient">Started</span>
          </div>
          <div className="apply-description">
            We pave the way for brilliant founders.
          </div>
          <a
            className="apply-button-container"
            href={"https://bsu5ua93efj.typeform.com/to/VkfgZ1QK"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="apply-button">APPLY NOW</div>
          </a>
        </div>
      </ScrollAnimation>

      <div className="scroll-to-top-container">
        <ScrollToTopArrow
          className="scroll-to-top-icon"
          onClick={() => heroScroll()}
        />
      </div>

      <div className="footer-container">
        Torum Technology Sdn. Bhd. © 2020 - 2022
      </div>
    </div>
  );
}

export default App;
